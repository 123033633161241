export default () => {
  const swiperOptions = {
    loop: true,
    centeredSlides: true,
    pagination: {
      el: '.js-swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      // 画面幅で動作を変える(767px)
      767: {
        slidesPerView: 1.5,
        spaceBetween: 32,
      },
    },
  }
  return new Swiper('.js-mainvisual-container', swiperOptions)
}
