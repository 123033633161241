export default () => {
  $('[role="tab"]').on('click', function() {
    const activateTabTabPanelName = $(this).attr('aria-controls')
    const $activateTab = $(this)
    const $activateTabPanel = $(
      `[aria-labelledby="${activateTabTabPanelName}"]`
    )

    $('[role="tab"], [role="tabpanel"]').removeClass('is-active')
    $activateTab.addClass('is-active')
    $activateTabPanel.addClass('is-active')
  })
}
